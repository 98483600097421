import React, { useEffect, useState } from "react";
import CustomTabs from "../CustomTabs/CustomTabs";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import Schedule from "@material-ui/icons/Schedule";
import { Store } from "@material-ui/icons";
import { ContactForm } from "./contactForm";

const useStyles = makeStyles(styles);

export function AboveFooter() {
  const classes = useStyles();

  const [colorData, setcolorData] = useState();

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setcolorData({ fontSize: 0 });
    }
  }, []);
  return (
    <div style={{ paddingTop: 20 }} className={classes.section}>
      <div className={classes.container}>
        <div id="nav-tabs">
          <CustomTabs
            style={{
              background: "#B4B4BC",
            }}
            tabs={[
              {
                tabName: <p style={colorData}>openingsuren</p>,
                tabIcon: Schedule,
                tabContent: (
                  <div>
                    <p className={classes.textCenter}>Maandag 10:00 - 17:00</p>
                    <p className={classes.textCenter}>Dinsdag 10:00 - 17:00</p>
                    <p className={classes.textCenter}>Woensdag Gesloten</p>
                    <p className={classes.textCenter}>
                      Donderdag 10:00 - 17:00
                    </p>
                    <p className={classes.textCenter}>Vrijdag 10:00 - 17:00</p>
                    <p className={classes.textCenter}>Zaterdag 10:00 - 18:00</p>
                    <p className={classes.textCenter}>
                      Zondag & Feestdagen 12:00 - 17:00
                    </p>
                    <p className={classes.textCenter}>24/31 dec: 10:00 - 15:00</p>
                    <p className={classes.textCenter}>25 dec/ 1 kan: gesloten</p>
                  </div>
                ),
              },
                {
                    tabName: <p style={colorData}>Contact</p>,
                    tabIcon: Store,
                    tabContent: <ContactForm />,
                }
            ]}
          />
        </div>
      </div>
    </div>
  );
}
